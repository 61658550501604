<template>
  <BaseForm class="pa-0">
    <template v-slot:fields>
      <v-col
        cols="12"
        class="py-1"
        v-for="{
          value,
          text,
          type,
          readonly,
          disabled,
          drowpdownValues,
        } in fields"
        :key="value"
      >
        <v-select
          v-if="type === 'dropdown'"
          v-model="objData[value]"
          :items="drowpdownValues.values"
          :item-text="drowpdownValues.text ? drowpdownValues.text : ''"
          :item-value="drowpdownValues.value ? drowpdownValues.value : ''"
          single-line
          dense
          solo
          background-color="#20212E"
          hide-details
          class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
        >
        </v-select>
        <DatePicker
          v-model="objData[value]"
          :data="objData"
          :keyData="value"
          :title="text"
          :existingModel="objData[value]"
          :notfull="true"
          v-else-if="
            type === 'datepicker' &&
            value === 'dueDate' &&
            tabDetails.vendStepDescr !== 'Quote'
          "
        ></DatePicker>
        <v-text-field
          v-else
          type="text"
          v-model="objData[value]"
          outlined
          dense
          background-color="darker_blue"
          color="white"
          hide-details
          :label="text"
          :readonly="readonly ? readonly : false"
          :disabled="disabled ? disabled : false"
        ></v-text-field>
      </v-col>
    </template>
    <template v-slot:buttons>
      <v-row class="py-3">
        <v-col class="py-0 px-1 text-center">
          <v-btn
            class="button elevation-5"
            color="primary"
            @click="saveSection"
            small
            block
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            class="elevation-5 button button--outlined"
            @click="resetSection"
            small
            block
          >
            Reset
          </v-btn>
        </v-col>
        <v-col cols="12" class="pt-2 mt-1">
          <v-divider class="outlined-button"></v-divider>
        </v-col>
      </v-row>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

export default {
  props: ["objData"],

  components: {
    BaseForm,
    DatePicker,
  },
  computed: {
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      docNo: "moduleOrders/docNo",
    }),
    ...mapState({
      ordersType: (state) => state.moduleOrders.ordersType,
      ordersTerm: (state) => state.moduleOrders.ordersTerm,
    }),

    fields() {
      const fields = [
        {
          text: "External Customer #",
          value: "externalCustomerNumber",
          type: "text",
        },
        {
          text: "Customer PO",
          value: "custPoRef",
          type: "text",
        },
        {
          text: "Contract Reference #",
          value: "contractNumberReference",
          type: "text",
        },
        // {
        //   text: "Due Date",
        //   value: "dueDate",
        //   type: "datepicker",
        //   hideDetails: true,
        // },
        {
          text: "Order Type",
          value: "order_type",
          type: "dropdown",
          drowpdownValues: {
            text: "typeDesc",
            value: "id",
            values: this.ordersType,
          },
        },
        {
          text: "Credit Term",
          value: "credit_term",
          type: "dropdown",
          drowpdownValues: {
            text: "termsDesc",
            value: "id",
            values: this.ordersTerm,
          },
        },
      ];

      return fields;
    },
  },
  methods: {
    ...mapActions({
      save_quote: "moduleOrders/save_quote",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
    }),

    async saveSection() {
      let {
        contractNumberReference,
        externalCustomerNumber,
        custPoRef,
        order_type,
        credit_term,
        dueDate,
      } = this.objData;
      await this.save_quote({
        contractNumberReference,
        externalCustomerNumber,
        custPoRef,
        orderTypeId: order_type,
        creditTermId: credit_term,
        dueDate,
        docType: this.tabDetails.docType,
        docId: this.tabDetails.id,
        invoice_no: this.tabDetails.docNo,
      });
      // await this.getOrderByDocNo(this.docNo);
    },
    resetSection() {
      let {
        contractNumberReference,
        externalCustomerNumber,
        custPoRef,
        order_type,
        credit_term,
      } = this.tabDetails;
      this.objData = {
        ...this.objData,
        contractNumberReference,
        externalCustomerNumber,
        custPoRef,
        order_type,
        credit_term,
        dueDate: parseFromIsoToFormat(this.tabDetails.dueDate, "yyyy-LL-dd"),
      };
    },
  },
};
</script>

<style></style>
