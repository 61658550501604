<template>
  <ValidationObserver v-slot="{ errors, reset }">
    <BaseForm class="dark_blue elevation-5 pa-1">
      <template v-slot:fields>
        <v-col
          cols="12"
          class="py-1"
          v-for="{ value, label, type, rules } in fields"
          :key="value"
        >
          <DatePicker
            v-if="type === 'datepicker'"
            v-model="obj[value]"
            :data="obj"
            :keyData="value"
            :title="label"
            :existingModel="obj[value]"
            :disabled="false"
          ></DatePicker>
          <v-tooltip
            v-else
            bottom
            :disabled="!(errors && errors[`${label}`] && errors[`${label}`].length)"
            color="warning"
          >
            <template v-slot:activator="{ on }">
              <ValidationProvider
                :rules="rules"
                :name="label"
                validate-on="change"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-on="on"
                  type="text"
                  v-model="obj[value]"
                  outlined
                  dense
                  background-color="darker_blue"
                  color="white"
                  :label="label"
                  hide-details
                  :error-messages="errors[0]"
                  :disabled="false"
                ></v-text-field>
              </ValidationProvider>
            </template>
            <span class="font-weight-bold dark_blue--text">{{
              !!(errors && errors[`${label}`] && errors[`${label}`].length)
                ? errors[`${label}`][0]
                : ""
            }}</span>
          </v-tooltip>
        </v-col>
      </template>
      <template v-slot:buttons>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            color="primary"
            class="elevation-5 px-2 py-0 button"
            @click="sendData"
            block
            :disabled="false"
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            @click="reset"
            class="elevation-5 px-2 py-0 button button--outlined"
            block
            :disabled="disabled"
          >
            Reset
          </v-btn>
        </v-col>
      </template>
    </BaseForm>
  </ValidationObserver>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  parseDateFromFormat,
  parseDate,
  parseFromIsoToFormat,
} from "@/helpers/mocks/dates";

export default {
  components: {
    BaseForm,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["disabled"],
  data() {
    return {
      docNo: null,
      orderID: null,
      fields: [
        { label: "Date", value: "payment_date", type: "datepicker" },
        { label: "Amount", value: "amount_paid", rules: "double" },
        { label: "Payment Reference", value: "payment_no" },
        { label: "Remarks", value: "remarks" },
      ],

      // obj: {
      //   date: "",
      //   amount: "",
      //   pay_ref: "",
      //   comment: "",
      // },
      obj: {
        invoice_no: "",
        payment_no: "",
        payment_date: "",
        amount_paid: "",
        currency: "USD",
        remarks: "",
      },
    };
  },
  async created() {
    await this.initLocalState();
  },
  computed: {
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
  },
  methods: {
    ...mapActions({
      createPayment: "moduleOrders/createPayment",
      getPaymentDetails: "moduleOrders/moduleDetails/getPaymentDetails",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
    }),

    async initLocalState() {
      const { id, docNo } = await this.tabDetails;
      this.docNo = docNo;
      this.orderID = id;

      this.obj.invoice_no = this.docNo;
    },

    async reset() {
      for (let key in this.obj) this.obj[key] = "";

      await this.initLocalState();
    },
    sendData() {
      let data = {
        ...this.obj,
        payment_date: this.obj.serverDate_payment_date
          ? parseDateFromFormat(
              this.obj.serverDate_payment_date,
              "yyyy-LL-dd HH-mm-ss",
              "yyyy-LL-dd"
            )
          : this.obj.payment_date,
      };

      this.createPayment({ data }).then((x) => {
        this.updateDetails({ name: "" });
        this.reset();
      });
    },
  },
};
</script>

<style lang="scss"></style>
