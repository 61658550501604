<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="450"
      offset-x
      :nudge-left="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="indigo" dark v-bind="attrs" v-on="on" icon small>
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
      </template>
      <div class="px-0 elevation-0 darker_blue">
        <v-row>
          <v-col class="btn--fit-col">
            <v-btn
              class="pa-1 ma-0 transparent elevation-0"
              dense
              @click="menu = false"
            >
              <v-icon dark class="red--text text--accent-4 ma-0">
                mdi-close-octagon
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-sheet :color="`2`" class="pa-3" v-if="objF.size_type == '-'">
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-sheet>

            <v-card
              class="dark_blue elevation-0"
              style="border: none"
              outlined
              v-else
            >
              <v-row
                v-for="(field, index) in fields"
                :key="index"
                class="darker_blue pa-2 elevation-0 mt-0"
                justify="space-around"
                style="
                  display: flex;
                  flex-flow: column;
                  text-align: justify;
                  text-align-last: right;
                "
              >
                <v-col
                  col="4"
                  class="btn--fit-col pa-1"
                  v-for="value in field"
                  :key="value.value"
                  style="width: 95% !important"
                >
                  <v-row>
                    <v-col
                      class="font-weight-normal font-italic btn--fit-col"
                      style="text-align-last: left; color: #dddddd"
                      col="4"
                      >{{ value.label }} :</v-col
                    >

                    <v-col
                      class="font-weight-normal wrapper"
                      v-if="value.value === 'MRComments'"
                    >
                      <v-text-field
                        type="text"
                        v-model="objF.MRComments"
                        outlined
                        height="15px"
                        flat
                        dense
                        hide-details
                        class="ml-2 pl-2 pr-2"
                      ></v-text-field>
                      <v-btn @click="saveMRComment()" x-small ma-0>
                        <v-icon dark small> mdi-content-save </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="font-weight-normal"
                      style="text-align-last: right"
                      v-else
                      >{{ objF[value.value] }}</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-menu>
  </div>
</template>
<script>
import BillOfSaleDetail from "@/components/OM/Details/BillOfSaleDetail.vue";
import CardDetails from "@/components/General/CardDetails.vue";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

import {
  get_EOO_sub_details,
  get_invoice_meta_details,
} from "@/helpers/api/Orders";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  props: ["obj"],
  components: {
    BillOfSaleDetail,
    CardDetails,
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    MRComment: "",
    fields: [
      [
        {
          label: "Equipment ID",
          value: "equipmentId",
        },
        {
          label: "Size/Type",
          value: "size_type",
        },
        { label: "Sal Ref", value: "salRef" },
        { label: "Year", value: "year" },
        { label: "Gate In", value: "gateIn" },
        { label: "M&R Comments", value: "MRComments" },
        { label: "Gate Out", value: "gateOut" },
        { label: "Invoice Num", value: "invoiceNum" },
        { label: "Invoice Date", value: "invoiceDate" },
        { label: "Sale Price", value: "salePrice" },
      ],
    ],
    objF: {
      equipmentId: "-",
      size_type: "-",
      year: "-",
      salRef: "-",
      gateIn: "-",
      MRComments: "-",
      gateOut: "-",
      invoiceNum: "-",
      invoiceDate: "-",
      salePrice: "-",
    },
  }),
  computed: {
    ...mapState({
      invoiceMetaDetails: (state) =>
        state.moduleOrders.moduleDetails.invoiceMetaDetails[0],
      equipmentMRComments: (state) =>
        state.moduleOrders.moduleDetails.equipmentMRComments,
    }),

    ...mapGetters({
      tabDetails: ["moduleOrders/tabDetails"],
    }),
    containerNo() {
      return this.obj ? this.obj.ContainerNo : "";
    },
  },
  async mounted() {
    if (this.obj.ContainerNo !== null) {
      await this.getMRCommentsOnContainer({
        EquipmentID: this.obj.ContainerNo,
      });
      this.MRComments = this.equipmentMRComments;
    }
  },
  watch: {
    containerNo: {
      immediate: true,
      async handler() {
        try {
          this.objF.equipmentId = this.obj.ContainerNo;
          this.objF.gateOut = this.obj.date_pickup;
          // call endpoint
          if (this.obj.ContainerNo) {
            let response = await Promise.allSettled([
              get_EOO_sub_details(this.obj.ContainerNo),
              get_invoice_meta_details(this.tabDetails.id),
            ]);

            if (response[0].value.pass) {
              if (response[0].value.data.length > 0) {
                this.objF.size_type = `${response[0].value.data[0].Size}${response[0].value.data[0].Type}`;

                // this.objF.salRef = response[0].value.data[0].header;
                this.objF.salRef = response[0].value.data[0].gated_custom_ref_a;

                this.objF.gateIn = parseFromIsoToFormat(
                  response[0].value.data[0].GateInDepot,
                  "yyyy-LL-dd",
                );

                // this.objF.invoiceNum = this.invoiceMetaDetails
                //   ? this.invoiceMetaDetails.invoice_meta_no
                //   : "";

                // this.objF.invoiceDate = this.invoiceMetaDetails
                //   ? parseFromIsoToFormat(
                //       this.invoiceMetaDetails.date_given,
                //       "yyyy-LL-dd",
                //     )
                //   : "";

                // if (this.objF.gateIn)
                //   this.objF.gateIn = parseFromIsoToFormat(
                //     this.objF.gateIn,
                //     "yyyy-LL-dd",
                //   );

                this.objF.year = response[0].value.data[0].MfgYear;
                this.objF.MRComments = this.equipmentMRComments;

                // this.objF.salePrice = this.invoiceMetaDetails
                //   ? `$${this.invoiceMetaDetails.amount}`
                //   : "";
              }
            }
            if (response[1].value.pass) {
              if (response[1].value.data.length > 0) {
                this.setInvoiceMetaDetails(response[1].value.data);
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
    invoiceMetaDetails: {
      immediate: true,
      handler() {
        if (this.invoiceMetaDetails) {
          this.objF.invoiceNum = this.invoiceMetaDetails
            ? this.invoiceMetaDetails.invoice_meta_no
            : "";

          this.objF.invoiceDate = this.invoiceMetaDetails
            ? parseFromIsoToFormat(
                this.invoiceMetaDetails.date_given,
                "yyyy-LL-dd",
              )
            : "";
          this.objF.salePrice = this.invoiceMetaDetails
            ? `$${this.invoiceMetaDetails.amount}`
            : "";
        }
      },
    },
    equipmentMRComments: {
      immediate: true,
      handler() {
        this.objF.MRComments = this.equipmentMRComments;
      },
    },
  },
  methods: {
    ...mapMutations({
      setInvoiceMetaDetails: "moduleOrders/moduleDetails/setInvoiceMetaDetails",
    }),
    ...mapActions({
      getInvoiceMetaDetails: "moduleOrders/moduleDetails/getInvoiceMetaDetails",
      getMRCommentsOnContainer:
        "moduleOrders/moduleDetails/getMRCommentsOnContainer",
      processContainerMRComment:
        "moduleOrders/moduleDetails/processContainerMRComment",
    }),
    async saveMRComment() {
      const mrComment = this.objF.MRComments;
      await this.processContainerMRComment({
        EquipmentID: this.objF.equipmentId,
        MRComment: mrComment,
      });
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  align-items: center;
}
</style>
