<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <BaseInlineTable :headerTable="headerTable" :items="items">
        <template v-slot:[`slot_dateCreated`]="{ item }">
          <span v-if="!item.new">{{
            parseFromIsoToFormat(item.dateCreated, "yyyy-LL-dd")
          }}</span>
        </template>
        <template v-slot:[`slot_view_access`]="{ item }">
          <v-select
            v-if="item.new"
            v-model="item.view_access"
            :items="viewAccess"
            outlined
            single-line
            dense
            full-width
            hide-details
            class="ma-0 rounded-0"
            background-color="#20212E"
          >
          </v-select>
          <span v-else>{{ item.view_access }}</span>
        </template>

        <template v-slot:[`slot_actions`]="{ item }" class="primary">
          <v-row>
            <v-col>
              <v-btn
                color="transparent"
                class="lighten-2 rounded-0 pa-0"
                icon
                @click="editComment(item)"
                v-if="!item.new"
              >
                <v-icon dark v-if="item.edit === true">mdi-content-save</v-icon>

                <v-icon dark v-else>mdi-account-edit</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="transparent"
                class="lighten-2 rounded-0 pa-0"
                @click="deleteComment(item)"
                icon
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </BaseInlineTable>
    </v-col>
    <v-col class="col-ajust pt-5">
      <v-btn
        class="button elevation-5"
        small
        :color="'primary'"
        @click="addNewComment"
        :disabled="flagEditing"
      >
        {{ !flagAddComment ? "New Comment" : "Save Comment" }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import BaseInlineTable from "@/components/General/BaseInlineTable.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  components: {
    TableReadOnly,
    BaseInlineTable,
    DatePicker,
  },
  async created() {
    await Promise.all([
      this.getNotesViewAccess(),
      this.getComments({ docRef: this.tabDetails.docNo }),
    ]);
  },
  computed: {
    ...mapState({
      comments: (state) => state.moduleOrders.moduleDetails.comments,
      viewAccess: (state) => state.moduleOrders.moduleDetails.viewAccess,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
    headerTable() {
      return generateHeaderData(this.header);
    },
  },

  data() {
    return {
      header: [
        { text: "Date", value: "dateCreated", width: "18%" },
        { text: "Message", value: "notes_text", width: "40%", edit: true },
        { text: "Access", value: "view_access", width: "15%" },
        { text: "By", value: "createdBy", width: "15%", noNew: true },
        { text: "", value: "actions", width: "12%" },
      ],
      items: [],
      flagAddComment: false,
      flagEditing: false,
    };
  },
  watch: {
    comments: {
      // immediate: true,
      handler() {
        // this.getComments({ docRef: this.tabDetails.docNo });
        this.items = [
          ...this.comments.map((x) => {
            return { ...x };
          }),
        ];
      },
    },
  },
  methods: {
    parseFromIsoToFormat,
    ...mapActions({
      getComments: "moduleOrders/moduleDetails/getComments",
      updateComment: "moduleOrders/moduleDetails/updateComment",
      deleteCommentAction: "moduleOrders/moduleDetails/deleteComment",
      createComment: "moduleOrders/moduleDetails/createComment",
      getNotesViewAccess: "moduleOrders/moduleDetails/getNotesViewAccess",
    }),
    editComment(item) {
      if (this.flagAddComment) {
        this.items.splice(this.items.length - 1, 1);
        this.flagAddComment = false;
      }
      let index = this.items.findIndex((x) => x.id === item.id);
      if (!item.edit) {
        this.$set(this.items[index], "edit", !item["edit"]);
        this.flagEditing = true;
      } else {
        if (item.notes_text !== this.comments[index].notes_text)
          this.updateComment({ docRef: this.tabDetails.docNo, data: item });
        else this.$set(this.items[index], "edit", !item["edit"]);
        this.flagEditing = false;
      }
    },
    deleteComment(item) {
      if (item.new) {
        let index = this.items.findIndex((x) => x.id === item.id);
        this.items.splice(index, 1);
        this.flagAddComment = !this.flagAddComment;
      } else
        this.deleteCommentAction({ docRef: this.tabDetails.docNo, data: item });
    },
    async addNewComment() {
      this.flagAddComment = !this.flagAddComment;
      if (this.flagAddComment) {
        this.items.push({
          dateCreated: "",
          notes_text: "",
          view_access: "PRIVATE",
          createdBy: "",
          new: true,
        });
      } else {
        {
          let response = await this.createComment({
            docRef: this.tabDetails.docNo,
            data: {
              doc_type: "SALEINVOICE",
              doc_ref: this.tabDetails.docNo,
              doc_id: this.tabDetails.id,
              note: this.items[this.items.length - 1].notes_text,
              view_access: this.items[this.items.length - 1].view_access,
            },
          });
          console.log(response);
          if (!response) {
            this.items.splice(this.items.length - 1, 1);
          }
        }
      }
    },
  },
};
</script>

<style></style>
