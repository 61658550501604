<template>
  <v-container class="pa-0">
    <v-row justify="space-around" class="pa-2">
      <v-col
        class="px-1 col-ajust"
        v-for="itemsDetail in itemsDetails"
        :key="itemsDetail.value"
      >
        <template v-if="itemsDetail.gateBuy">
          <v-card elevation="5" class="pa-0 dark-blue fill-height" v-gate-buy="orderType">
            <v-list-item-content class="ma-0 pa-1 d-flex fill-height text-center">
              <v-list-item-title class="ma-0 pa-0 primary--text font-weight-black">
                {{ itemsDetail.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="ma-0 px-2 text-caption font-weight-medium"
                tag="span"
              >
                <template>
                  {{ objData[itemsDetail.value] }}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card>
        </template>
        <v-card elevation="5" class="pa-0 dark-blue fill-height" v-else>
          <v-list-item-content class="ma-0 pa-1 d-flex fill-height text-center">
            <v-list-item-title class="ma-0 pa-0 primary--text font-weight-black">
              {{ itemsDetail.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="ma-0 px-2 text-caption font-weight-medium"
              tag="span"
            >
              <template>
                {{ objData[itemsDetail.value] }}
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      creditData: (state) => state.moduleOrders.moduleDetails.creditData,
    }),
    ...mapGetters({
      tabDetails: ["moduleOrders/tabDetails"],
      isTBD: ["moduleOrders/moduleDetails/isTBD"],
      order_bal: ["moduleOrders/moduleDetails/order_bal"],
    }),
    orderType() {
      return this.tabDetails?.order_type;
    },
    objData() {
      let value = {
        vit_vendorTotal: `${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(2)}`,
        vit_qty: this.tabDetails.vit_qty,
        amount_paid: this.tabDetails.amount_paid
          ? `${parseFloat(this.tabDetails.amount_paid).toFixed(2)}`
          : "0",
        OrderBal:
          `${parseFloat(this.order_bal).toFixed(2)}` -
          `${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(2)}`,
        balance: this.creditData
          ? `${parseFloat(this.creditData.balance).toFixed(2)}`
          : "",
      };
      return value;
    },
  },
  data() {
    return {
      itemsDetails: [
        { title: "Order Total", value: "vit_vendorTotal" },
        { title: "Amount Paid", value: "amount_paid" },
        { title: "Order Bal", value: "OrderBal" },
        { title: "Total # Units on Order", value: "vit_qty" },
        { title: "Credit Balance", value: "balance", gateBuy: true },
      ],
    };
  },
};
</script>

<style></style>
