<template>
  <ValidationObserver v-slot="{ errors, reset, valid }">
    <BaseForm class="dark_blue elevation-5 pa-1">
      <template v-slot:fields>
        <v-row>
          <v-col cols="12" class="py-1">
            <v-autocomplete
              :items="lineItemsParse"
              v-model="lineItemSelected"
              @input="selectedLineItem"
              label="Line Item/s"
              color="white"
              outlined
              dense
              background-color="darker_blue"
              hide-details
              append-icon=""
              return-object
              item-value="itemID"
              item-text="lineId"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-1">
            <ValidationProvider
              :rules="`required|max_num:${lineItemSelected?.qty - 1}`"
              name="Reduce affected line item - qty"
              v-slot="{ errors }"
            >
              <v-text-field
                type="text"
                v-model="afeccted.incomingQty"
                outlined
                dense
                background-color="darker_blue"
                color="white"
                label="Reduce affected line item - qty"
                :hide-details="!errors.length"
                :error-messages="errors[0]"
                :disabled="false"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" class="py-1">
            <hr />
          </v-col>
          <v-col cols="12" class="py-1">
            <v-btn
              class="button elevation-5"
              block
              color="primary"
              @click="addNewLineItem"
              :disabled="!lineItemSelected"
            >
              Add DV Line Item
            </v-btn>
          </v-col>
          <v-col
            class="py-1"
            v-for="{ value, label, rules, readOnly, col } in fields"
            :cols="col || '12'"
            :key="value"
          >
            <v-text-field
              v-if="value === 'depot_size_type_condt'"
              type="text"
              :value="objParse"
              outlined
              dense
              background-color="darker_blue"
              color="white"
              :label="label"
              hide-details
              :error-messages="errors[0]"
              :readonly="readOnly"
            ></v-text-field>
            <ValidationProvider :rules="rules" :name="label" v-slot="{ errors }" v-else>
              <v-text-field
                type="text"
                v-model="obj[value]"
                outlined
                dense
                background-color="darker_blue"
                color="white"
                :label="label"
                hide-details
                :error-messages="errors[0]"
                :readonly="readOnly"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttons>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            color="primary"
            class="elevation-5 px-2 py-0 button"
            @click="sendData(reset)"
            block
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="py-0 px-1 text-center">
          <v-btn
            @click="resetForm(reset)"
            class="elevation-5 px-2 py-0 button button--outlined"
            block
            :disabled="disabled"
          >
            Reset
          </v-btn>
        </v-col>
      </template>
    </BaseForm>
  </ValidationObserver>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BaseForm,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["disabled"],
  data() {
    return {
      lineItemSelected: null,

      orderID: null,
      fields: [
        { label: "", value: "reference", readOnly: true },
        {
          label: "Container - Selected",
          value: "depot_size_type_condt",
          readOnly: true,
          rules: "required",
        },
        { label: "DV - Qty", value: "qty", col: "6", rules: "required" },
        { label: "DV - Price", value: "buyerPrice", col: "6", rules: "required" },
      ],

      afeccted: {
        itemID: "",
        currentQty: "",
        incomingQty: "",
      },
      obj: {
        reference: "DV-CONTAINER",
        equipmentSize: "",
        equipmentType: "",
        equipmentCondition: "",
        depot: "",
        country: "",
        state: "",
        city: "",
        depotId: null,
        qty: null,
        buyerPrice: "",
        total: null,
        itemType: "DV-CONTAINER",
      },
    };
  },

  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      DVContainer: (state) => state.moduleOrders.moduleDetails.DVContainer,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      docNo: "moduleOrders/docNo",
    }),
    lineItemsParse() {
      const arr = [];
      this.lineItems.map((x, index) => {
        if (x.itemType === "CONTAINER") {
          arr.push({
            ...x,
            lineId: `L${index + 1} ${x.Location} ${x.sizeDescr} ${x.typeCode} ${
              x.condDescr
            }`,
          });
        }
      });
      return arr;
    },
    objParse() {
      return `${this.obj.depot} ${this.obj.equipmentSize} ${this.obj.equipmentType} ${this.obj.equipmentCondition}`;
    },
  },
  watch: {
    DVContainer: {
      deep: true,
      handler() {
        if (this.DVContainer !== null)
          this.obj = {
            reference: "DV-CONTAINER",
            equipmentSize: this.DVContainer.Size,
            equipmentType: this.DVContainer.Type,
            equipmentCondition: this.DVContainer.Cndtn,
            depot: this.DVContainer.Depot,
            country: this.DVContainer.CountryAlhpa2,
            state: this.DVContainer.StateAlpha2,
            city: this.DVContainer.CityName,
            depotId: this.DVContainer.DepotId,
            qty: "",
            buyerPrice: "",
            itemType: "DV-CONTAINER",
          };
        else {
          this.afeccted = {
            itemID: "",
            currentQty: "",
            incomingQty: "",
          };
          this.obj = {
            reference: "DV-CONTAINER",
            equipmentSize: "",
            equipmentType: "",
            equipmentCondition: "",
            depot: "",
            country: "",
            state: "",
            city: "",
            depotId: null,
            qty: null,
            buyerPrice: "",
            total: null,
            itemType: "DV-CONTAINER",
          };
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setFlagAddLineItems: "moduleOrders/moduleDetails/setFlagAddLineItems",
      setFlagAddingDVContainer: "moduleOrders/moduleDetails/setFlagAddingDVContainer",
      setAffectedDVContainer: "moduleOrders/moduleDetails/setAffectedDVContainer",
    }),
    ...mapActions({
      createPayment: "moduleOrders/createPayment",
      getPaymentDetails: "moduleOrders/moduleDetails/getPaymentDetails",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      createDVContainer: "moduleOrders/moduleDetails/createDVContainer",
    }),
    async addNewLineItem() {
      this.setFlagAddLineItems(true);
      this.setFlagAddingDVContainer(true);
      this.setAffectedDVContainer(this.lineItemSelected);
    },
    selectedLineItem(value) {
      this.afeccted.currentQty = value.qty;
      this.afeccted.incomingQty = value.qty === 1 ? 0 : 1;
      this.afeccted.itemID = value.itemID;
    },

    async resetForm(fc) {
      this.obj = {
        reference: "DV-CONTAINER",
        equipmentSize: "",
        equipmentType: "",
        equipmentCondition: "",
        depot: "",
        country: "",
        state: "",
        city: "",
        depotId: null,
        qty: null,
        buyerPrice: "",
        total: null,
        itemType: "DV-CONTAINER",
      };
      for (let key in this.afeccted) this.afeccted[key] = "";

      this.lineItemSelected = null;
      fc();
    },
    async sendData(fc) {
      const incoming = this.afeccted.currentQty - this.afeccted.incomingQty;
      let obj = {
        docNo: this.docNo,
        invoice_id: this.tabDetails.id,
        affected: {
          itemID: this.afeccted.itemID,
          currentQty: this.afeccted.currentQty,
          incomingQty: incoming,
        },
        dvcontainer: { ...this.obj, total: this.obj.qty * this.obj.buyerPrice },
      };
      // console.log(obj);
      await this.createDVContainer(obj);
      this.resetForm(fc);
    },
  },
};
</script>

<style lang="scss"></style>
