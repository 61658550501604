var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseForm',{staticClass:"pa-0",scopedSlots:_vm._u([{key:"fields",fn:function(){return _vm._l((_vm.fields),function({
        value,
        text,
        type,
        readonly,
        disabled,
        drowpdownValues,
      }){return _c('v-col',{key:value,staticClass:"py-1",attrs:{"cols":"12"}},[(type === 'dropdown')?_c('v-select',{staticClass:"lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined",attrs:{"items":drowpdownValues.values,"item-text":drowpdownValues.text ? drowpdownValues.text : '',"item-value":drowpdownValues.value ? drowpdownValues.value : '',"single-line":"","dense":"","solo":"","background-color":"#20212E","hide-details":""},model:{value:(_vm.objData[value]),callback:function ($$v) {_vm.$set(_vm.objData, value, $$v)},expression:"objData[value]"}}):(
          type === 'datepicker' &&
          value === 'dueDate' &&
          _vm.tabDetails.vendStepDescr !== 'Quote'
        )?_c('DatePicker',{attrs:{"data":_vm.objData,"keyData":value,"title":text,"existingModel":_vm.objData[value],"notfull":true},model:{value:(_vm.objData[value]),callback:function ($$v) {_vm.$set(_vm.objData, value, $$v)},expression:"objData[value]"}}):_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","background-color":"darker_blue","color":"white","hide-details":"","label":text,"readonly":readonly ? readonly : false,"disabled":disabled ? disabled : false},model:{value:(_vm.objData[value]),callback:function ($$v) {_vm.$set(_vm.objData, value, $$v)},expression:"objData[value]"}})],1)})},proxy:true},{key:"buttons",fn:function(){return [_c('v-row',{staticClass:"py-3"},[_c('v-col',{staticClass:"py-0 px-1 text-center"},[_c('v-btn',{staticClass:"button elevation-5",attrs:{"color":"primary","small":"","block":""},on:{"click":_vm.saveSection}},[_vm._v(" Save ")])],1),_c('v-col',{staticClass:"py-0 px-1 text-center"},[_c('v-btn',{staticClass:"elevation-5 button button--outlined",attrs:{"small":"","block":""},on:{"click":_vm.resetSection}},[_vm._v(" Reset ")])],1),_c('v-col',{staticClass:"pt-2 mt-1",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"outlined-button"})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }