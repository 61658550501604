<template>
  <v-container class="pa-0" fluid>
    <TopSection :objData="objData" />
    <QuoteSection :objData="objData" />
    <BottomSection :objData="objData" />
    <template v-if="customFields.length">
      <BaseSpanLine title="Custom Fields" />

      <CustomFieldsFormNewOrder3
        :customFields="customFields"
        class="transparent elevation-0"
      >
        <template v-slot:buttons="{ item }">
          <v-row class="py-3">
            <v-col class="py-0 px-1 text-center">
              <v-btn
                class="button elevation-5"
                color="primary"
                small
                block
                @click="updateCustomField(item)"
              >
                Save
              </v-btn>
            </v-col>
            <v-col class="py-0 px-1 text-center">
              <v-btn
                class="elevation-5 button button--outlined"
                @click="resetCustomField"
                small
                block
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </CustomFieldsFormNewOrder3>
    </template>
  </v-container>
</template>

<script>
import TopSection from "@/components/OM/Details/LeftPanel/TopSection.vue";
import QuoteSection from "@/components/OM/Details/LeftPanel/QuoteSection.vue";
import BottomSection from "@/components/OM/Details/LeftPanel/BottomSection.vue";
import CustomFieldsFormNewOrder3 from "@/components/OM/CustomFields/FormNewOrder3.vue";
import BaseSpanLine from "@/components/General/BaseSpanLine.vue";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    TopSection,
    QuoteSection,
    BottomSection,
    CustomFieldsFormNewOrder3,
    BaseSpanLine,
  },
  computed: {
    ...mapState({
      customFieldsState: (state) =>
        state.moduleOrders.moduleDetails.customFields,
    }),
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
      docNo: "moduleOrders/docNo",
    }),
  },
  data() {
    return {
      objData: {},
      customFields: [],
      customFieldsCopy: [],
    };
  },
  watch: {
    tabDetails: {
      immediate: true,
      handler() {
        this.objData = {
          ...this.tabDetails,
          dueDate: parseFromIsoToFormat(this.tabDetails.dueDate, "yyyy-LL-dd"),
        };
      },
    },
    docNo: {
      immediate: true,
      async handler() {
        this.customFields = [
          ...this.customFieldsState.map((x) => {
            return { ...x };
          }),
        ];
      },
    },
  },
  methods: {
    ...mapActions({
      get_custom_field_by_order: "moduleOrders/get_custom_field_by_order",
      updateCustomFieldValue: "moduleOrders/updateCustomFieldValue",
    }),
    resetCustomField() {
      this.customFields = [
        ...this.customFieldsState.map((x) => {
          return { ...x };
        }),
      ];
    },
    async updateCustomField(item) {
      await this.updateCustomFieldValue(item);
    },
  },
};
</script>

<style></style>
