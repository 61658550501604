<template>
  <v-container class="pa-0">
    <v-data-table
      class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
      dense
      fixed-header
      :headers="headerData"
      :items="creditsWithBalance"
      hide-default-footer
      disable-pagination
      :height="'30vh'"
      width="100%"
      item-class="text-center"
      item-key="id"
      :loading="loading"
    >
      <template v-slot:item="{ item, index }">
        <tr @click="addSelectedCredit(item, index)" :style="{ cursor: 'pointer' }">
          <td
            v-for="header in headerData"
            :key="header.value"
            :class="item.selected ? 'primary' : ''"
          >
            <span v-if="header.value === 'dateCreated'"
              >{{ parseFromIsoToFormat(item.dateCreated, "yyyy-LL-dd") }}
            </span>
            <span v-else-if="header.value === 'dateUpdated'"
              >{{ parseFromIsoToFormat(item.dateUpdated, "yyyy-LL-dd") }}
            </span>
            <span v-else>{{ item[header.value] }} </span>
          </td>
        </tr>
      </template>

      <template v-slot:[`body.append`]="{ headers }">
        <tr v-if="append && append.totalAmount">
          <td v-for="(header, i) in headers" :key="i" class="primary">
            <div
              v-if="header.value == 'dateCreated'"
              class="font-weight-medium black--text text-subtitle-2"
            >
              Total Amount:
            </div>
            <div
              v-if="header.value == 'dateUpdated'"
              class="font-weight-medium black--text text-subtitle-2"
            >
              {{ amount }}
            </div>
          </td>
        </tr>
        <tr v-if="append && append.balance">
          <td class="primary" v-for="(header, i) in headers" :key="i">
            <div
              v-if="header.value == 'dateCreated'"
              class="font-weight-medium black--text text-subtitle-2"
            >
              Total Balance:
            </div>
            <div
              v-if="header.value == 'dateUpdated'"
              class="font-weight-medium black--text text-subtitle-2"
            >
              {{ balance }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
export default {
  props: ["append", "customerEntityId"],
  computed: {
    ...mapState({
      credits: (state) => state.moduleOrders.credits,
      creditsTotal: (state) => state.moduleOrders.creditsTotal,
    }),
    ...mapGetters({}),

    headerData() {
      return generateHeaderData(this.header, "min-width");
    },
    creditsWithBalance() {
      return this.items.filter((x) => x.balance > 0);
    },
  },
  async created() {
    this.loading = true;

    if (this.credits.length) {
      this.items = this.credits;
      this.amount = this.creditsTotal.amount;

      this.balance = this.creditsTotal.balance;
    }

    this.loading = false;
  },

  data() {
    return {
      url: "",
      loading: false,
      header: [
        { text: "Invoice No.", value: "invoice_no" },
        { text: "Balance", value: "balance" },
        { text: "Date Created", value: "dateCreated" },
        { text: "Date Updated", value: "dateUpdated" },
      ],
      items: [],
      amount: null,
      balance: null,
    };
  },
  watch: {
    credits() {
      this.items = this.credits;
    },
  },
  methods: {
    ...mapMutations({
      setCredits: "moduleOrders/setCredits",
    }),

    parseFromIsoToFormat,
    addSelectedCredit(item) {
      let index = this.items.findIndex((x) => x.id === item.id);

      let value = item.selected ? !item.selected : true;
      this.$set(this.items[index], "selected", value);
      this.$set(this.items[index], "max", this.items[index].balance);

      this.setCredits(this.items);
    },
  },
};
</script>

<style lang="scss" scoped>
.min-width {
  min-width: none;
}
</style>
