<template>
  <v-row class="flex-column">
    <v-col class="py-1">
      <v-data-table
        class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
        dense
        fixed-header
        hide-default-footer
        height="100%"
        :header-props="{ sortIcon: 'mdi-chevron-up' }"
        :headers="headerTable"
        :items="items"
        width="100%"
        disable-pagination
      >
        <template v-slot:item="{ item, index }">
          <tr>
            <td
              v-for="header in headerTable"
              :key="header.value"
              :class="invoiceSelected && invoiceSelected.id === item.id ? 'primary' : ''"
              @click="header.value !== 'del' && selectRow(item)"
            >
              <span class="ma-0 rounded-0">
                {{ item[header.value] }}
              </span>

              <v-btn
                v-if="header.value == 'del'"
                class="ma-0 pa-0"
                icon
                x-small
                color="orange lighten-1"
                @click="deleteInvoice(index)"
              >
                <v-icon class="ma-0 pa-0"> mdi-delete </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col class="py-1">
      <InvoiceForm
        :invoiceSelected="invoiceSelected"
        @resetInvoiceSelected="invoiceSelected = null"
      />
    </v-col>
  </v-row>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapState } from "vuex";
import InvoiceForm from "@/components/OM/Details/InvoiceForm.vue";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
export default {
  components: {
    TableReadOnly,
    InvoiceForm,
  },
  created() {
    this.getInvoiceMetaDetails(this.tabDetails.id);
  },
  computed: {
    ...mapState({
      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      invoiceMetaDetails: (state) => state.moduleOrders.moduleDetails.invoiceMetaDetails,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: ["moduleOrders/tabDetails"],
    }),
    EOOItems() {
      let items = [];
      for (let i = 0; i < this.EOO.length; i++) {
        this.EOO[i].map((x) => items.push({ ...this.lineItems[i], ...x }));
      }

      return items;
    },
    headerTable() {
      return generateHeaderData(this.header);
    },
    items() {
      return this.invoiceMetaDetails.map((x) => {
        x.amount = parseFloat(x.amount).toFixed(2);
        x.date_given = parseFromIsoToFormat(x.date_given, "yyyy-LL-dd");
        return x;
      });
    },
  },
  data() {
    return {
      header: [
        { text: "Invoice #", value: "invoice_meta_no" },
        { text: "Date", value: "date_given" },
        { text: "Amount", value: "amount" },
        { text: "SAL Reference", value: "custom_ref_a" },
        { text: "--", value: "del" },
      ],
      invoiceSelected: null,
    };
  },
  methods: {
    ...mapActions({
      getInvoiceMetaDetails: "moduleOrders/moduleDetails/getInvoiceMetaDetails",
      deleteInvoiceMeta: "moduleOrders/moduleDetails/deleteInvoiceMeta",
    }),
    selectRow(item) {
      console.log("aa");

      this.invoiceSelected = item;
    },
    deleteInvoice(index) {
      this.deleteInvoiceMeta(this.invoiceMetaDetails[index]);

      if (
        this.invoiceSelected &&
        this.invoiceMetaDetails[index].id === this.invoiceSelected.id
      ) {
        console.log("fkmjnfce");
        this.invoiceSelected = null;
        console.log(this.invoiceSelected);
      }
    },
  },
};
</script>

<style></style>
