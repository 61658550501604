<template>
  <v-container fluid>
    <template v-if="spinnerShowOD">
      <v-progress-linear
        v-model="value"
        :active="spinnerShowOD"
        :indeterminate="true"
        :query="true"
        color="blue-grey"
      ></v-progress-linear>
    </template>
    <ItemsDetails />
    <v-row justify="end" class="pa-2">
      <v-col cols="12">
        <ItemsTable class="dark-blue" :header="header" @clickRow="clickRow" />
      </v-col>
      <v-col cols="6">
        <SubTableLineItem />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsDetails from "@/components/OM/Details/ItemsDetails";
import ItemsTable from "@/components/OM/Details/ItemsTable";
import SubTableLineItem from "@/components/OM/Details/SubTableLineItem";

import { mapMutations, mapState } from "vuex";
export default {
  components: {
    ItemsDetails,
    ItemsTable,
    SubTableLineItem,
  },
  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
    }),
    ...mapState(["spinnerShowOD"]),
  },
  data() {
    return {
      value: 0,
      query: false,
      show: true,
      interval: 0,
      header: [
        { text: "Line", value: "itemID", width: "4%" },
        { text: "Type", value: "itemType", width: "10%" },
        { text: "Item Ref", value: "docItemID", width: "8%" },
        { text: "Depot", value: "Location", color: "green", width: "10%" },
        { text: "Country", value: "countryName" },
        { text: "State", value: "stateName" },
        { text: "City", value: "cityName" },
        { text: "Size", value: "sizeDescr", color: "green" },
        { text: "Type", value: "typeCode", color: "green" },
        { text: "Condition", value: "condDescr" },
        { text: "Release #", value: "rn2_release_ref" },
        { text: "Qty", value: "qty", width: "50px" },
        { text: "Unit Price", value: "buyerPrice", width: "7%" },
        { text: "Total", value: "Total", width: "7%" },
        { text: "", value: "actions", width: "10%" },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setItemSelected: "moduleOrders/moduleDetails/setItemSelected",
      setPanelsOpen: "moduleOrders/moduleDetails/setPanelsOpen",
      setIndexSelected: "moduleOrders/moduleDetails/setIndexSelected",
    }),
    clickRow(item) {
      this.setPanelsOpen([0]);
      let index = this.lineItems.findIndex((x) => x.itemID === item.itemID);

      // this.setIndexSelected(null);

      this.setIndexSelected(index);
    },
  },
};
</script>

<style></style>
