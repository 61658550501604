<template>
  <v-container class="">
    <v-col style="right 0">
      <v-btn
        class="button elevation-5"
        x-small
        color="green"
        @click="refresh"
        icon
        style="position: absolute; right: 50px; float: right; top: 11px"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </v-col>

    <v-expansion-panels
      class="expansion-panel dark-blue"
      :value="expandedValues"
      multiple
      popout
    >
      <v-expansion-panel
        v-for="(item, i) in eooItemValue"
        :key="item.itemID"
        class="dark-blue"
      >
        <v-row justify="end">
          <v-btn
            class="button elevation-5"
            color="primary"
            small
            @click="allItemsLine"
            v-if="indexSelected !== null"
          >
            Show All
          </v-btn>
        </v-row>
        <v-expansion-panel-header class="pa-0 px-1">
          <RightPanelHeader :item="item" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-container class="px-1 py-0">
            <BaseForm v-if="equipmentOnOrder.length">
              <template v-slot:fields>
                <v-row v-for="(equipment, index) in formValues[i]" :key="equipment.id">
                  <v-row class="px-0 pt-2 align-center">
                    <v-col cols="12" class="pb-3" v-if="index === 0">
                      <v-text-field
                        type="text"
                        dense
                        hide-details
                        background-color="darker_blue"
                        color="white"
                        label="Release #"
                        v-model="item.rn2_release_ref"
                        inset
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4" class="pb-1 pr-2 small-icon-calendar">
                      <DatePicker
                        v-model="equipment.date_pickup"
                        keyData="date_pickup"
                        hideDetails="true"
                        :data="equipment"
                        :title="`Out Date`"
                        :existingModel="equipment.date_pickup"
                        :disabled="
                          !(item.rn2_release_ref !== null && item.rn2_release_ref.length)
                        "
                      >
                      </DatePicker>
                    </v-col>

                    <v-col
                      :cols="formValues[i].length === 1 ? 8 : 7"
                      class="pb-1 center-icon"
                    >
                      <Autocomplete
                        :obj="equipment"
                        :label="`Equipment ID`"
                        :equipmentOnOrder="equipmentOnOrder"
                        :entry="
                          entriesUnique[
                            `${item.Location}${item.sizeDescr}${item.condDescr}${item.typeCode}`
                          ]
                        "
                        objKey="ContainerNo"
                        :test="`${item.Location}${item.sizeDescr}${item.condDescr}${item.typeCode}`"
                        :stateObj="EOO[i][index]"
                      >
                      </Autocomplete>
                    </v-col>
                    <v-col cols="1" v-if="formValues[i].length !== 1" class="text-center">
                      <v-btn
                        class="lighten-2 rounded-0 pa-0 ma-auto"
                        x-small
                        icon
                        color="red"
                        :disabled="
                          loading !== false ||
                          spinnerShowOD ||
                          objPickedUpArr.includes(
                            `${EOO[i][index].itemID}_${EOO[i][index].id}`
                          )
                        "
                        @click="deleteEooConfirmation(equipment, i)"
                      >
                        <!-- @click="deleteEoo(equipment, i)" -->
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row justify="space-between">
                  <v-col class="pr-2 py-2">
                    <v-btn
                      class="button primary"
                      block
                      :disabled="loading !== false"
                      @click="saveEquipmentOnOrder(equipmentOnOrder[i], i)"
                      :loading="loading == 'save-' + i"
                      :id="'save-' + i"
                    >
                      Save
                    </v-btn>
                  </v-col>
                  <v-col class="pl-2 py-2">
                    <v-btn class="button button--outlined" block @click="reset(i)">
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </BaseForm>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <ModalEooConfirmationVue
      title="Confirmation"
      :show="showEOOConfirmation"
      v-if="showEOOConfirmation"
      @closeConfimationEOO="closeConfimationEOO"
      @deleteEoo="deleteEoo"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import RightPanelHeader from "@/components/OM/Details/RightPanelHeader.vue";
import BaseForm from "@/components/General/BaseForm.vue";

import DatePicker from "@/components/General/DatePickerGeneral.vue";
import Autocomplete from "@/components/OM/Details/Autocomplete.vue";

import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

import { trans_log_order } from "@/helpers/api/Orders/index.js";
import ModalEooConfirmationVue from "@/components/Auctions/ModalEooConfirmation.vue";

export default {
  components: {
    RightPanelHeader,
    BaseForm,
    DatePicker,
    Autocomplete,
    ModalEooConfirmationVue,
  },
  watch: {
    EOO: {
      immediate: true,
      async handler() {
        if (this.indexEOOSaved === null) {
          if (this.eooIndex !== null) {
            this.equipmentOnOrder[this.eooIndex] = [];
            this.EOO[this.eooIndex].map((x) => {
              x.date_pickup = x.date_pickup
                ? parseFromIsoToFormat(x.date_pickup, "yyyy-LL-dd")
                : x.date_pickup;

              this.equipmentOnOrder[this.eooIndex].push({ ...x });
            });
          } else {
            this.equipmentOnOrder = [];
            this.EOO.map((x) =>
              this.equipmentOnOrder.push(
                x.map((values) => {
                  if (values.date_pickup)
                    values.date_pickup = parseFromIsoToFormat(
                      values.date_pickup,
                      "yyyy-LL-dd"
                    );
                  return { ...values };
                })
              )
            );
          }
        } else {
          this.equipmentOnOrder[this.indexEOOSaved] = [];
          this.EOO[this.indexEOOSaved].map((x) => {
            x.date_pickup = x.date_pickup
              ? parseFromIsoToFormat(x.date_pickup, "yyyy-LL-dd")
              : x.date_pickup;

            this.equipmentOnOrder[this.indexEOOSaved].push({ ...x });
          });
        }
      },
    },
  },
  computed: {
    ...mapState({
      itemSelected: (state) => state.moduleOrders.moduleDetails.itemSelected,
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      indexSelected: (state) => state.moduleOrders.moduleDetails.indexSelected,
      stepperIndex: (state) => state.moduleOrders.moduleDetails.stepperIndex,
      entries: (state) => state.moduleOrders.moduleDetails.Equipments,
      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      eooIndex: (state) => state.moduleOrders.moduleDetails.eooIndex,
      EOOParse() {
        return this.EOO.map((x) => {
          if (x.date_pickup)
            x.date_pickup = parseFromIsoToFormat(x.date_pickup, "yyyy-LL-dd");
          return x;
        });
      },
      spinnerShowOD: (state) => state.spinnerShowOD,
      objPickedUpArr: (state) => state.moduleOrders.moduleDetails.objPickedUpArr,
    }),
    ...mapGetters({
      tabDetails: ["moduleOrders/tabDetails"],
      uniqueDepotLineItems: ["moduleOrders/moduleDetails/uniqueDepotLineItems"],
    }),
    formValues() {
      return this.indexSelected === null || this.eooIndex
        ? this.equipmentOnOrder
        : [this.equipmentOnOrder[this.indexSelected]];
    },
    itemSelectedLength() {
      return Object.keys(this.itemSelected).length;
    },
    eooItemValue() {
      if (this.indexSelected === null) {
        return this.lineItems;
      } else {
        return [this.lineItems[this.indexSelected]];
      }
    },
    entriesUnique() {
      const uniqueLineItemsByDepot = {};
      this.uniqueDepotLineItems.forEach((x, i) => {
        // if (
        //   !uniqueLineItemsByDepot[
        //     `${x.Location}${x.sizeDescr}${x.condDescr}${x.typeCode}`
        //   ]
        // ) {
        uniqueLineItemsByDepot[
          `${x.Location}${x.sizeDescr}${x.condDescr}${x.typeCode}`
        ] = this.entries[i];
        // }
      });
      return uniqueLineItemsByDepot;
    },
    // release_ref(){
    //   return !this.itemSelectedLength ? this.
    // },

    valid() {
      return (index) => {
        let invalid = true;
        for (let equipment of this.equipmentOnOrder[index]) {
          if (equipment.ContainerNo && equipment.ContainerNo.length) {
            invalid = false;
            break;
          }
        }

        return invalid;
      };
    },
  },
  data() {
    return {
      loading: false,
      equipmentOnOrder: [],
      equipmentOnOrderCopy: [],
      equipmentOnOrderItems: [],
      fields: [
        {
          text: "Release #",
          value: "release_ref",
          col: 12,
          type: "text",
          disabled: true,
        },
        {
          text: "Equipment Id",
          value: "ContainerNo",
          col: 5,
          disabled: false,
          type: "text",
          appendIcon: "mdi-information-variant",
          eventname: "clickAppend",
          key: null,
        },
        {
          text: "Gate Out Date",
          value: "date_pickup",
          col: 7,
          type: "DatePicker",
          disabled: false,
          hideDetails: true,
          key: null,
        },
      ],
      fieldsArray: [],
      expandedValues: [0],
      indexEOOSaved: null,
      deleteEooItem: null,
      deleteEOOIndex: null,
      showEOOConfirmation: false,
    };
  },
  methods: {
    ...mapMutations({
      setItemSelected: "moduleOrders/moduleDetails/setItemSelected",
      setIndexSelected: "moduleOrders/moduleDetails/setIndexSelected",
      setEOOIndex: "moduleOrders/moduleDetails/setEOOIndex",
      setInfoMessage: "moduleOrders/moduleDetails/setInfoMessage",
    }),
    ...mapActions({
      updateEquipmentOnOrderSingle:
        "moduleOrders/moduleDetails/updateEquipmentOnOrderSingle",
      actionRefreshAll: "moduleOrders/moduleDetails/refreshAllPanels",
      getEOO: "moduleOrders/moduleDetails/getEOO",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      deleteEOOAction: "moduleOrders/moduleDetails/deleteEOO",
    }),
    closeConfimationEOO() {
      this.deleteEooItem = null;
      this.deleteEOOIndex = null;
      this.showEOOConfirmation = false;
    },
    async deleteEooConfirmation(equipment, index) {
      this.deleteEooItem = equipment;
      this.deleteEOOIndex = index;
      this.showEOOConfirmation = true;
    },
    async deleteEoo() {
      let equipment = this.deleteEooItem;
      let index = this.deleteEOOIndex;

      this.loading = "save-" + index;

      let { id } = this.tabDetails;

      let message = [];
      this.setInfoMessage(null);

      if (this.lineItems[index].rn2_release_ref) {
        message.push("Please re-issue Release, line items have changed.");
      }
      if (this.tabDetails.sent === "Y" || this.tabDetails.sent === "R") {
        message.push("Please re-issue the Bill Of Sale, line items have changed.");
        message.push("Please go to payment details for refunds and DV.");
      }
      this.setInfoMessage({
        panel: null,
        message,
        disabledPanels: [2],
      });

      await this.deleteEOOAction({
        invoice_id: id,
        itemCntr: [
          {
            id: equipment.id,
          },
        ],
      });

      try {
        let cntrNo_log = equipment.hasOwnProperty("ContainerNo")
          ? equipment.ContainerNo
          : "";
        trans_log_order({
          order_id: id,
          doc_table: "invoice_items_container",
          trans_desc: `EOO removed ${cntrNo_log}`,
          application: "EquipmentOnOrderSidePanel",
          trans_action: "DELETE",
          old_value: cntrNo_log,
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      this.setEOOIndex(index);
      this.updateDetails({ name: "EOO", index });

      this.loading = false;
      this.deleteEooItem = null;
      this.deleteEOOIndex = null;
      this.showEOOConfirmation = false;
    },
    refresh() {
      this.getEOO(true);
    },

    allItemsLine() {
      this.reset(this.indexSelected);
      this.setIndexSelected(null);
      // this.setItemSelected({});
    },
    reset(index) {
      let values = this.EOOParse[index].map((x) => {
        return { ...x };
      });
      this.$set(this.equipmentOnOrder, index, values);
    },
    async saveEquipmentOnOrder(values, index) {
      this.loading = "save-" + index;

      let { id } = this.tabDetails;
      this.indexEOOSaved = index;
      let requests = values.filter(
        (value, index) => value.ContainerNo && value.ContainerNo.length != 0
        // value.date_pickup &&
        // value.date_pickup.length != 0,
      );

      requests.forEach((element, index) => {
        if (element.date_pickup) element.date_pickup = element.date_pickup.slice(0, 10);
        element.docId = id;
      });

      await this.updateEquipmentOnOrderSingle(requests);

      this.updateDetails({ name: "EOO", index });

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.expansion-panel {
  .v-expansion-panel-content__wrap {
    padding: 0px 4px !important;
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 0px;
  }
}

.small-icon-calendar {
  .text-field__calendar {
    .v-input__slot {
      padding: 0px 6px !important;
    }
    .v-icon {
      font-size: medium !important;
    }

    .v-input__prepend-inner {
      margin: auto !important;
    }
  }
}
.center-icon {
  .v-input__append-inner {
    margin: auto !important;
  }
}
</style>
