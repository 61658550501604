<template>
  <BaseForm class="pa-0">
    <template v-slot:fields>
      <v-col
        cols="12"
        class="py-1"
        v-for="{
          value,
          text,
          type,
          readonly,
          disabled,
          drowpdownValues,
        } in fields"
        :key="value"
      >
        <v-select
          v-if="type === 'dropdown'"
          v-model="objData[value]"
          :items="drowpdownValues.values"
          :item-text="drowpdownValues.text ? drowpdownValues.text : ''"
          :item-value="drowpdownValues.value ? drowpdownValues.value : ''"
          single-line
          dense
          solo
          background-color="#20212E"
          hide-details
          class="lighten-2 pa-0 ma-0 elevation-5 text-center non-outlined.v-text-field--outlined"
        >
        </v-select>
        <DatePicker
          v-model="objData[value]"
          :data="objData"
          :keyData="value"
          :title="text"
          :existingModel="objData[value]"
          :notfull="true"
          v-else-if="
            type === 'datepicker' &&
            value === 'dueDate' &&
            tabDetails.vendStepDescr !== 'Quote'
          "
        ></DatePicker>
        <v-text-field
          v-else
          type="text"
          v-model="objData[value]"
          outlined
          dense
          background-color="darker_blue"
          color="white"
          hide-details
          :label="text"
          :readonly="readonly ? readonly : false"
          :disabled="disabled ? disabled : false"
        ></v-text-field>
      </v-col>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";

export default {
  props: ["objData"],

  components: {
    BaseForm,
    DatePicker,
  },
  computed: {
    fields() {
      const fields = [
        {
          text: "Internal Customer/Account #",
          value: "entityID",
          disabled: true,
          type: "text",
        },
        {
          text: "Internal ID",
          value: "id",
          disabled: true,
          type: "text",
        },
        {
          text: "Doc #",
          value: "docNo",
          disabled: true,
          type: "text",
        },
      ];

      return fields;
    },
  },
};
</script>

<style></style>
