<template>
  <v-row
    class="dark_blue elevation-5 py-2 px-4 rounded"
    style="border: thin solid #ffc107 !important"
    v-if="message.length"
  >
    <v-col cols="12" v-for="m of message" :key="m">
      <span class="text-subtitle-1 font-weight-regular warning--text">
        <v-icon color="warning" class="pr-2">mdi-information</v-icon>
        {{ m }}
        <!-- <v-icon color="warning">mdi-information-variant</v-icon> -->
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      infoMessage: (state) => state.moduleOrders.moduleDetails.infoMessage,
    }),
    message() {
      return this.infoMessage ? this.infoMessage.message : [];
    },
  },
};
</script>

<style></style>
