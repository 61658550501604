<template>
  <v-row>
    <v-col col="9" style="display: flex; flex-flow: column">
      <v-btn
        class="button elevation-5 pa-2 ma-2"
        small
        color="primary"
        @click="updateOrderPipeline"
      >
        Dev Sync Pipeline
      </v-btn>
      <!-- <TableReadOnly :headerTable="headerTable" :items="items" /> -->
      <div>
        <span>HEADERS</span><br />
        <pre>{{ headers }}</pre>
      </div>
      <br />
      <div>
        <span>DETAILS</span><br />
        <pre>{{ details }}</pre>
      </div>
    </v-col>
    <!-- <v-col> <PaymentForm /> </v-col> -->
  </v-row>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import PaymentForm from "@/components/OM/Details/PaymentForm.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  get_single_order_details_by_docNo,
  get_single_order_headers_by_docNo,
  get_single_order_details_by_docNo_docType_inv,
  get_single_order_headers_by_docNo_docType_inv,
} from "@/helpers/api/indexv2";

import { update_order_pipeline } from "@/helpers/api/Orders";

export default {
  components: {
    TableReadOnly,
    PaymentForm,
  },
  computed: {
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: "moduleOrders/tabDetails",
    }),
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  data() {
    return {
      headers: null,
      details: null,
    };
  },
  async created() {
    const details = await get_single_order_details_by_docNo_docType_inv(
      this.docNo,
    );
    const headers = await get_single_order_headers_by_docNo_docType_inv(
      this.docNo,
    );

    this.headers = headers;
    this.details = details;
  },
  methods: {
    async updateOrderPipeline() {
      let id = this.tabDetails.id;
      console.log("id", id);
      const resData = await update_order_pipeline({ invoice_id: id });
    },
  },
};
</script>

<style></style>
