<template>
  <v-container class="pa-0">
    <v-row justify="end" style="gap: 5px">
      <v-col cols="auto" class="py-1" v-if="addedLineItems.length">
        <v-btn
          class="button elevation-5 outlined-button"
          small
          @click="saveAddedLineItems"
          :disabled="emptyNewLineItem"
        >
          Save Line Items
        </v-btn>
      </v-col>
      <v-col cols="auto" class="py-1" v-if="addedLineItems.length">
        <v-btn
          class="button elevation-5 white--text"
          outlined
          style="border-color: red"
          small
          @click="clearAddedLineItems"
        >
          Clear Added Line Items
        </v-btn>
      </v-col>
      <v-col cols="auto" class="py-1">
        <v-btn class="button elevation-5" small color="primary" @click="addNewLineItem">
          Add Line Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="max-height: 40vh">
        <v-data-table
          class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
          dense
          fixed-header
          hide-default-footer
          disable-pagination
          height="100%"
          width="100%"
          item-class="text-center"
          item-key="itemID"
          :headers="headerData"
          :items="lineItems"
          :loading="itemsTableFlag"
          @click:row="clickRow"
          :mobile-breakpoint="0"
        >
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-row justify="space-around">
              <v-col class="col-ajust">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="lighten-2 rounded-0 pa-0 ma-auto"
                      x-small
                      icon
                      color="primary"
                      v-on="on"
                    >
                      <v-icon dark>mdi-rectangle</v-icon>
                    </v-btn>
                  </template>
                  <span> Container </span>
                </v-tooltip>
              </v-col>
              <v-col class="col-ajust">
                <v-btn
                  class="lighten-2 rounded-0 pa-0 ma-auto"
                  x-small
                  icon
                  color="primary"
                  @click="!item.edit ? editItem(item) : saveItem(item, index)"
                  :disabled="item.edit && disableQtyInput"
                >
                  <v-icon dark>{{
                    `${!item.edit ? "mdi-account-edit" : "mdi-content-save"}`
                  }}</v-icon>
                </v-btn>
              </v-col>
              <v-col class="col-ajust" v-if="lineItems.length !== 1">
                <v-btn
                  class="lighten-2 rounded-0 pa-0 ma-auto"
                  x-small
                  icon
                  color="primary"
                  @click="removeItem(item)"
                  :disabled="spinnerShowOD"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.itemID`]="{ value }">
            {{ `L${lineItems.findIndex((x) => x.itemID === value) + 1}` }}
          </template>
          <template v-slot:[`item.buyerPrice`]="{ item }">
            <input
              v-model="item.buyerPrice"
              v-if="item.edit"
              class="non-outlined ma-0 rounded-0 text-center"
              :style="{
                height: '100%',
                backgroundColor: '#303030',
                outline: 'none',
                color: '#ffff',
                caretColor: '#3284f4',
              }"
            />
            <span v-else> $ {{ parseFloat(item.buyerPrice).toFixed(2) }} </span>
          </template>
          <template v-slot:[`item.qty`]="{ item, index }">
            <v-tooltip bottom v-if="item.edit" :disabled="item.qty > 0" color="warning">
              <template v-slot:activator="{ on }">
                <input
                  v-on="on"
                  :value="item.qty"
                  @input="controlQty($event, item, index)"
                  class="non-outlined ma-0 rounded-0 text-center"
                  :style="{
                    height: '100%',
                    backgroundColor: '#303030',
                    outline: 'none',
                    color: '#ffff',
                    caretColor: '#3284f4',
                  }"
                />
              </template>
              <span class="font-weight-bold dark_blue--text">
                {{ `Qty must be greater that 1` }}
              </span>
            </v-tooltip>

            <span v-else> {{ item.qty }}</span>
          </template>
          <template v-slot:[`item.Total`]="{ item }">
            $ {{ parseFloat(item.qty * item.buyerPrice).toFixed(2) }}
          </template>
          <template v-slot:[`body.append`]="{ headers }">
            <tr v-for="(item, index) in addedLineItems" :key="item.itemID">
              <td v-for="{ value } in headers" :key="value">
                <span v-if="value === 'Total'">
                  $ {{ parseFloat(item.qty * item.buyerPrice).toFixed(2) }}
                </span>
                <span v-if="value === 'itemID'">
                  {{ `L${lineItems.length + (index + 1)}` }}
                </span>
                <v-row
                  justify="space-around"
                  v-else-if="value === 'actions'"
                  style="width: 100%"
                >
                  <v-col class="col-ajust" v-if="lineItems.length !== 1">
                    <v-btn
                      class="lighten-2 rounded-0 pa-0 ma-auto"
                      x-small
                      icon
                      color="primary"
                      @click="removeAddedLineItem(index)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <span v-else-if="value === 'qty'">
                  <input
                    :value="item.qty"
                    @input="controlQty($event, item, index)"
                    :style="{
                      height: '100%',
                      backgroundColor: '#303030',
                      outline: 'none',
                      color: '#ffff',
                      caretColor: '#3284f4',
                      width: '100%',
                    }"
                  />
                </span>
                <span v-else-if="value === 'buyerPrice'">
                  <input
                    v-model="item.buyerPrice"
                    :style="{
                      height: '100%',
                      backgroundColor: '#303030',
                      outline: 'none',
                      color: '#ffff',
                      caretColor: '#3284f4',
                      width: '100%',
                    }"
                  />
                </span>
                <span v-else-if="value === 'itemType'">
                  <v-select
                    :items="['CONTAINER', 'DV-CONTAINER']"
                    v-model="item.itemType"
                    append-icon=""
                    dense
                    hide-details
                    flat
                    background-color="#303030"
                    solo
                  ></v-select>
                  <!-- <input
                    v-model="item.buyerPrice"
                    :style="{
                      height: '100%',
                      backgroundColor: '#303030',
                      outline: 'none',
                      color: '#ffff',
                      caretColor: '#3284f4',
                      width: '100%',
                    }"
                  /> -->
                </span>
                <span v-else>
                  {{ item[value] }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

import { trans_log_order } from "@/helpers/api/Orders/index.js";

export default {
  props: ["header", "classe", "itemsTableFlag"],

  created() {
    this.lineItemsCopy = JSON.parse(JSON.stringify(this.lineItems));
  },
  computed: {
    ...mapState({
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      panelsOpen: (state) => state.moduleOrders.moduleDetails.panelsOpen,
      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      hasEOOLineItems: (state) => state.moduleOrders.moduleDetails.hasEOOLineItems,
      pickedUpLineItems: (state) => state.moduleOrders.moduleDetails.pickedUpLineItems,
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
      spinnerShowOD: (state) => state.spinnerShowOD,
      flagAddLineItems: (state) => state.moduleOrders.moduleDetails.flagAddLineItems,
      addedLineItemSelected: (state) =>
        state.moduleOrders.moduleDetails.addedLineItemSelected,
    }),
    ...mapGetters({
      entriesUnique: ["moduleOrders/moduleDetails/entriesUnique"],
      hasEOOFull: ["moduleOrders/moduleDetails/hasEOOFull"],
      getInventoryQtySummsAvailablesQtys:
        "moduleQtySumm/getInventoryQtySummsAvailablesQtys",
      tabDetails: "moduleOrders/tabDetails",
      orderTypeDesc: "moduleOrders/moduleDetails/orderTypeDesc",
    }),
    isGateBuySoft() {
      return this.orderTypeDesc === "Gate Buy Soft";
    },
    headerData() {
      return generateHeaderData(this.header, "min-width");
    },
    maxQtyValues() {
      let obj = {};
      let arr = [...this.lineItems, ...this.addedLineItems];
      arr.map((x) => {
        if (obj.hasOwnProperty(`${x.Location}_${x.sizeDescr}_${x.typeCode}`)) {
          obj[`${x.Location}_${x.sizeDescr}_${x.typeCode}`] =
            obj[`${x.Location}_${x.sizeDescr}_${x.typeCode}`] - Number(x.qty);
        } else {
          obj[`${x.Location}_${x.sizeDescr}_${x.typeCode}`] =
            this.getInventoryQtySummsAvailablesQtys[
              `${x.Location}_${x.sizeDescr}_${x.typeCode}`
            ] - Number(x.qty);
        }
      });
      return obj;
    },
    emptyNewLineItem() {
      return !!this.addedLineItems.filter((x) =>
        x.buyerPrice.length && x.qty && x.itemType ? false : true
      ).length;
    },
  },
  data() {
    return {
      method: undefined,
      lineItemsCopy: [],
      disableQtyInput: false,
      orderId: null,
      addedLineItems: [],
    };
  },
  watch: {
    EOO() {
      let obj = {};
      let objPickedUp = {};
      let objPickedUpArr = [];

      for (let index = 0; index < this.lineItems.length; index++) {
        let eoo = this.EOO[index];
        eoo = eoo.filter((x) => x.ContainerNo !== null && x.ContainerNo.length);
        let pickedUp = eoo.filter((x) => x.date_pickup !== null);

        eoo.map((value) => {
          if (value.date_pickup) {
            objPickedUp[`${value.itemID}`] = pickedUp.length;
            objPickedUpArr.push(`${value.itemID}_${value.id}`);
          }
        });
        obj[this.lineItems[index].itemID] = eoo.length;
      }
      this.setHasEOOLineItems(obj);
      this.setObjPickedUpArr(objPickedUpArr);
      this.setPickedUpLineItems(objPickedUp);
    },
    lineItems: {
      immediate: true,
      handler() {
        this.lineItemsCopy = JSON.parse(JSON.stringify(this.lineItems));
      },
    },
    // flagAddLineItems() {
    //   console.log("here");
    //   if (!this.flagAddLineItems) {
    //     this.removeAddedLineItem(this.addedLineItems.length);
    //   }
    // },
    addedLineItemSelected() {
      if (this.addedLineItemSelected !== null) {
        let obj = {
          itemID: this.lineItems.length + (this.addedLineItems + 1),
          Location: this.addedLineItemSelected.Depot,
          cityName: this.addedLineItemSelected.CityName,
          stateName: this.addedLineItemSelected.StateAlpha2,
          countryName: this.addedLineItemSelected.CountryAlhpa2,
          condDescr: this.addedLineItemSelected.Cndtn,
          sizeDescr: this.addedLineItemSelected.Size,
          typeCode: this.addedLineItemSelected.Type,
          depotId: this.addedLineItemSelected.DepotId,
          qty: "",
          buyerPrice: "",
          rn2_release_ref: "",
        };
        this.addedLineItems.push(obj);
        this.setAddedLineItemSelected(null);
      }
    },
  },
  methods: {
    ...mapMutations({
      setItemSelected: "moduleOrders/moduleDetails/setItemSelected",
      setIndexSelected: "moduleOrders/moduleDetails/setIndexSelected",
      setPanelsOpen: "moduleOrders/moduleDetails/setPanelsOpen",
      setHasEOOLineItems: "moduleOrders/moduleDetails/setHasEOOLineItems",
      setPickedUpLineItems: "moduleOrders/moduleDetails/setPickedUpLineItems",
      setObjPickedUpArr: "moduleOrders/moduleDetails/setObjPickedUpArr",
      setInventoryQtySumms: "moduleQtySumm/setInventoryQtySumms",
      setSpinnerShowOD: "setSpinnerShowOD",
      setInfoMessage: "moduleOrders/moduleDetails/setInfoMessage",
      setFlagAddLineItems: "moduleOrders/moduleDetails/setFlagAddLineItems",
      setAddedLineItemSelected: "moduleOrders/moduleDetails/setAddedLineItemSelected",
    }),
    ...mapActions({
      printViewBOSAction: "moduleOrders/moduleDetails/printViewBOS",
      changeSentValueSingleOrderDetails:
        "moduleOrders/moduleDetails/changeSentValueSingleOrderDetails",
      changeQtyLineItem: "moduleOrders/moduleDetails/changeQtyLineItem",
      changeQtyLineItemGB: "moduleOrders/moduleDetails/changeQtyLineItemGB",
      removeLineItem: "moduleOrders/moduleDetails/removeLineItem",
      actionCancelOrder: "moduleOrders/cancelOrder",
      getDepotsAll: "moduleOrders/getDepotsAll",
      saveAddedLineItemsStore: "moduleOrders/moduleDetails/saveAddedLineItems",
    }),

    async cancelOrder() {
      try {
        this.orderId = this.lineItems[0].id;
      } catch (error) {}

      await this.actionCancelOrder({
        docType: "Invoice",
        orderId: this.orderId,
      });

      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },

    handlerEventButton(method, item) {
      this.method = method;
      if (this[method]) {
        this[method](item);
      }
    },
    clickRow(item) {
      if (!this.method) this.$emit("clickRow", item);
      this.method = false;
    },

    removeItem(item) {
      this.method = true;

      this.removeLineItem({
        docNo: item.docNo,
        invoice_id: item.id,
        lineItem: [
          {
            itemID: item.itemID,
          },
        ],
      });
    },
    async editItem(item) {
      this.method = true;

      this.$set(item, "edit", true);
      this.setSpinnerShowOD(false);
    },
    async saveItem(item, index) {
      const self = this;

      this.method = true;
      this.$set(item, "edit", false);
      this.setInfoMessage(null);

      if (this.isGateBuySoft) {
        this.changeQtyLineItemGB({
          docNo: item.docNo,
          invoice_id: item.id,
          lineItem: [
            {
              itemID: item.itemID,
              currentQty: self.lineItemsCopy[index].qty,
              incomingQty: item.qty,
              qty: item.qty,
              oldQty: self.lineItemsCopy[index].qty,
              price: item.buyerPrice,
            },
          ],
          index,
        });
      } else {
        // Update item qty
        let message = [];

        if (item.rn2_release_ref) {
          message.push("Please re-issue Release, line items have changed.");
        }

        this.changeQtyLineItem({
          docNo: item.docNo,
          invoice_id: item.id,
          lineItem: [
            {
              itemID: item.itemID,
              currentQty: self.lineItemsCopy[index].qty,
              incomingQty: item.qty,
              qty: item.qty,
              oldQty: self.lineItemsCopy[index].qty,
              price: item.buyerPrice,
            },
          ],
          index,
        });

        // Bill of sale must be sent
        if (this.tabDetails.sent === "Y" || this.tabDetails.sent === "R") {
          message.push("Please re-issue the Bill Of Sale, line items have changed.");
          message.push("Please go to payment details for refunds and DV.");
        }

        this.setInfoMessage({
          panel: null,
          message,
          disabledPanels: [2],
        });
      }

      try {
        trans_log_order({
          order_id: item.id,
          doc_table: "invoice_items",
          trans_desc: `Line item qty changed from ${self.lineItemsCopy[index].qty} to ${item.qty}`,
          application: "ItemsTable",
          trans_action: "UPDATE",
          old_value: `${self.lineItemsCopy[index].qty}`,
          new_value: `${item.qty}`,
        });
      } catch (error) {
        console.log(error);
      }
      // }
    },
    controlQty(event, item, index) {
      const value = event.target.value;

      let _maxQtySumm =
        Number(item.qty) +
        Number(this.maxQtyValues[`${item.Location}_${item.sizeDescr}_${item.typeCode}`]);
      if (Number(value) <= _maxQtySumm) {
        this.setInfoMessage(null);
        item.qty = Number(value);
      } else {
        item.qty = Number(value);

        this.setInfoMessage({
          panel: null,
          message: isNaN(
            this.maxQtyValues[`${item.Location}_${item.sizeDescr}_${item.typeCode}`]
          )
            ? ["There is not more items availables"]
            : [
                `There is only ${
                  Number(item.qty) +
                  this.maxQtyValues[`${item.Location}_${item.sizeDescr}_${item.typeCode}`]
                }
              items available
              `,
              ],
        });
      }

      this.$forceUpdate();
    },
    async addNewLineItem() {
      this.setFlagAddLineItems(true);
    },
    removeAddedLineItem(index) {
      this.addedLineItems.splice(index, 1);
    },
    clearAddedLineItems() {
      this.addedLineItems = [];
    },
    async saveAddedLineItems() {
      if (!this.emptyNewLineItem) {
        let data = this.addedLineItems.map((x) => {
          return {
            equipmentSize: x.sizeDescr,
            equipmentType: x.typeCode,
            equipmentCondition: x.condDescr,
            depot: x.Location,
            country: x.countryName,
            state: x.stateName,
            city: x.cityName,
            depotId: x.depotId,
            qty: x.qty,
            buyerPrice: x.buyerPrice,
            total: x.qty * x.buyerPrice,
            itemType: x.itemType,
          };
        });
        await this.saveAddedLineItemsStore({
          DocNo: this.tabDetails.docNo,
          lineItems: data,
        });

        this.addedLineItems = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.min-width {
  min-width: none;
}
.create-order-table {
  position: absolute;
  bottom: 0;
}
</style>
