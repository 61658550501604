<template>
  <v-container fluid class="pa-0">
    <ValidationObserver v-slot="{ invalid, errors, reset }">
      <BaseForm class="py-1">
        <template v-slot:fields>
          <v-row>
            <v-col :cols="12" class="pa-1">
              <v-tooltip
                bottom
                :disabled="!(errors && errors['Invoice #'] && errors['Invoice #'].length)"
                color="warning"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    rules="required"
                    name="Invoice #"
                    validate-on="change"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="text"
                      v-model="obj.invoice_meta_no"
                      outlined
                      dense
                      background-color="darker_blue"
                      color="white"
                      label="Invoice #"
                      hide-details
                      :error-messages="errors[0]"
                      v-on="on"
                      :disabled="!EOOItems.length"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <span class="font-weight-bold dark_blue--text">{{
                  !!(errors && errors["Invoice #"] && errors["Invoice #"].length)
                    ? errors["Invoice #"][0]
                    : ""
                }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pa-1">
              <DatePicker
                :data="obj"
                keyData="date_given"
                title="Date"
                :disabled="!EOOItems.length"
                :rules="`required`"
              ></DatePicker>
            </v-col>
            <v-col cols="12" class="pa-1">
              <ValidationProvider
                rules="required"
                name="Invoice #"
                validate-on="change"
                v-slot="{ errors }"
              >
                <v-text-field
                  type="text"
                  v-model="obj.amount"
                  outlined
                  dense
                  background-color="darker_blue"
                  color="white"
                  label="Amount"
                  hide-details
                  :disabled="!EOOItems.length"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                type="text"
                v-model="obj.custom_ref_a"
                outlined
                dense
                background-color="darker_blue"
                color="white"
                label="Sal Ref"
                hide-details
                readonly
                :disabled="!EOOItems.length"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="flex-column">
            <v-col>
              <v-btn
                class="elevation-5 px-2 py-0 button primary"
                outlined
                block
                small
                @click="ShowHideEOO = !ShowHideEOO"
              >
                {{ ShowHideEOO ? "Hide list of Equipment" : "Shows list of Equipment" }}
                <v-icon v-if="ShowHideEOO" right dark> mdi-eye-off </v-icon>
                <v-icon v-else right dark> mdi-eye </v-icon>
              </v-btn>
            </v-col>
            <v-col class="py-1 elevation-5" v-show="ShowHideEOO">
              <v-sheet elevation="1" color="elevation-5 ">
                <div class="d-flex">
                  <v-btn
                    class="elevation-5 ml-auto button outlined-button"
                    outlined
                    small
                    @click="addAllEOOsItems"
                  >
                    Select all Equipments
                  </v-btn>
                </div>
                <v-list-item v-for="EOOItem of EOOItems" :key="EOOItem.id">
                  <v-list-item-content class="pa-0">
                    <v-row
                      class="elevation-5 white--text pa-2 rounded text-caption font-weight-bold"
                      @click="addEOO(EOOItem)"
                      :class="
                        obj.EOO.find((x) => x.ContainerNo === EOOItem.ContainerNo)
                          ? 'primary'
                          : ' dark_blue'
                      "
                    >
                      <v-col class="text-center">
                        <span>
                          {{ `${EOOItem.ContainerNo}` }}
                        </span>
                      </v-col>

                      <v-col class="text-center">
                        <span>
                          {{ `${EOOItem.sizeDescr}${EOOItem.typeCode}` }}
                        </span>
                      </v-col>
                      <v-col class="text-center">
                        <span>
                          {{ `${EOOItem.Location}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-sheet>
            </v-col>
          </v-row>
        </template>
        <template v-slot:buttons>
          <v-col class="py-2 px-1 text-center">
            <v-btn
              class="elevation-5 px-0 py-0 button primary"
              outlined
              block
              @click="sendData(reset)"
              :disabled="invalid || !obj.EOO.length"
            >
              Save
            </v-btn>
          </v-col>
          <v-col class="py-0 px-1 text-center">
            <v-btn
              @click="resetValues(reset)"
              class="elevation-5 px-2 py-0 button button--outlined"
              outlined
              block
            >
              Reset
            </v-btn>
          </v-col>
        </template>
      </BaseForm>
    </ValidationObserver>
  </v-container>
</template>

<script>
import BaseForm from "@/components/General/BaseForm.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { generateSAL } from "@/helpers/utils/index.js";
import { parseDateFromFormat, parseFromFormat } from "@/helpers/mocks/dates.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: ["invoiceSelected"],
  components: {
    BaseForm,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters({
      tabDetails: ["moduleOrders/tabDetails"],
    }),
    ...mapState({
      EOO: (state) => state.moduleOrders.moduleDetails.EOO,
      lineItems: (state) => state.moduleOrders.moduleDetails.lineItems,
      invoiceMetaDetails: (state) => state.moduleOrders.moduleDetails.invoiceMetaDetails,
    }),
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: ["moduleOrders/tabDetails"],
    }),
    EOOItems() {
      if (this.invoiceSelected) {
        // let indexes = [];
        // let eoo = [];
        // this.invoiceSelected.EOO.map((x) => {
        //   for (let index = 0; index < this.EOO.length; index++) {
        //     let eooFiltered = this.EOO[index].filter(
        //       (value) => value.ContainerNo === x.ContainerNo,
        //     );
        //     if (eooFiltered.length) {
        //       eoo.push(eooFiltered[0]);
        //       indexes.push(index);
        //       break;
        //     }
        //   }
        // });

        // return indexes.map((x, index) => {
        //   return { ...this.lineItems[x], ...eoo[index] };
        // });
        let items = [];
        for (let i = 0; i < this.EOO.length; i++) {
          this.EOO[i].map((x) => {
            if (
              x.ContainerNo &&
              this.lineItems[i].rn2_release_ref &&
              this.lineItems[i].rn2_release_ref.length
              // &&
              // !this.EOOinvoice.includes(x.ContainerNo)
            )
              items.push({ ...this.lineItems[i], ...x });
          });
        }
        return items;
      } else {
        let items = [];
        for (let i = 0; i < this.EOO.length; i++) {
          this.EOO[i].map((x) => {
            if (
              x.ContainerNo &&
              this.lineItems[i].rn2_release_ref &&
              this.lineItems[i].rn2_release_ref.length &&
              !this.EOOinvoice.includes(x.ContainerNo)
            )
              items.push({ ...this.lineItems[i], ...x });
          });
        }
        return items;
      }
    },
    EOOinvoice() {
      let invoiceArray = [];
      this.invoiceMetaDetails.map((invoiceDetails) =>
        invoiceDetails.EOO.map((x) => invoiceArray.push(x.ContainerNo))
      );
      return invoiceArray;
    },
    date_given() {
      return this.obj.date_given;
    },
  },
  data() {
    return {
      obj: {
        invoice_meta_no: "",
        date_given: "",
        custom_ref_a: "",
        amount: "",
        EOO: [],
      },
      ShowHideEOO: false,
    };
  },
  watch: {
    date_given() {
      if (this.obj.date_given.length) {
        this.obj.custom_ref_a = generateSAL(
          parseFromFormat(
            this.obj.date_given,
            this.invoiceSelected ? "yyyy-LL-dd" : "yyyy-LL-dd HH-mm-ss"
          ),
          "US"
        );
      }
    },
    invoiceSelected() {
      if (this.invoiceSelected) {
        this.ShowHideEOO = true;
        this.obj = { ...this.invoiceSelected };
      } else {
        (this.obj = {
          invoice_meta_no: "",
          date_given: "",
          custom_ref_a: "",
          amount: "",
          EOO: [],
        }),
          (this.ShowHideEOO = false);
      }
    },
  },
  methods: {
    ...mapActions({
      createInvoiceMeta: "moduleOrders/moduleDetails/createInvoiceMeta",
      updateInvoiceMeta: "moduleOrders/moduleDetails/updateInvoiceMeta",
    }),
    addAllEOOsItems() {
      let EOOItems = this.EOOItems.map((x) => {
        return { ContainerNo: x.ContainerNo };
      });
      this.obj.EOO = EOOItems;
    },
    async sendData(resetValidation) {
      if (this.obj.id) {
        console.log(
          "🚀 ~ file: InvoiceForm.vue ~ line 298 ~ sendData ~ updateInvoiceMeta"
        );
        this.updateInvoiceMeta(this.obj);
      } else {
        await this.createInvoiceMeta({
          ...this.obj,
          order_id: this.tabDetails.id,
          date_given: this.obj["serverDate_date_given"],
          EOO: this.obj.EOO,
        });
      }

      this.resetValues(resetValidation);
    },
    resetValues(reset) {
      for (let x in this.obj) {
        if (x !== "EOO") this.obj[x] = "";
        else this.obj[x] = [];
      }
      reset();
      this.$emit("resetInvoiceSelected");
    },
    addEOO(eooItem) {
      let index = this.obj.EOO.findIndex((x) => x.ContainerNo === eooItem.ContainerNo);

      if (index >= 0) this.obj.EOO.splice(index, 1);
      else this.obj.EOO.push({ ContainerNo: eooItem.ContainerNo });
    },
  },
};
</script>

<style></style>
